import "./Header.css";
import header from "../header.png";

export const Header = () => {
  return (
    <div className="header">
        <div className="row header-special">
            <img className="header-img" src={header} alt="Ja till Nato" />
            <h1 className="offscreen">Ja till Nato</h1>
        </div>

    </div>
  );
}
