import React from 'react';
import './Subscribe.css';
import pin from '../karnkraftjatack.svg';
import pil from '../pil.png';

export class Subscribe extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        name: "",
        surname: "",
        email: "",
        zipcode: "",
        consent: false
      },
      isSubmitting: false,
      isError: false,
      isSuccess: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e: any) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      values: {
        ...this.state.values,
        [name]: value
      }
    }, () => console.log(this.state));
  }

  submitForm(e: any) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    fetch("/collect.php", {
      method: "POST",
      body: JSON.stringify(this.state.values),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      this.setState({ isSubmitting: false });
      return res.json();
    }).then(data => {
      if (data.hasOwnProperty("error")) {
        this.setState({ isError: true, isSuccess: false });
      } else {
        this.setState({ isError: false, isSuccess: true });
      }
    }).catch(err => {
      this.setState({ isSubmitting: false, isError: true, isSuccess: false });
    });
  }

  render() {
    const isSubmitting = this.state.isSubmitting;
    const isValid = this.state.values.name &&
      this.state.values.surname &&
      this.state.values.email &&
      this.state.values.consent && this.state.values.zipcode;
    const isSuccess = this.state.isSuccess;
    let error;
    let success;

    if (this.state.isError) {
      error = <p className="error">Det gick inte att skicka in dina uppgifter. Vänligen försök igen senare.</p>;
    }
    if (this.state.isSuccess) {
      success = <p className="success">Tack för din medverkan!</p>
    }

    return (
      <div>
      <form className="subscribe row" onSubmit={this.submitForm}>
        <div className="pin">
          <div className="pin-holder">
            <div className="pil">
              <img src={pil} className="pil-img bounce" alt="pil" />
            </div>
          </div>
        </div>
        <div className="subrow">
          <div>
            <h2 className="title">Vill du också att Sverige ska bli medlem i Nato? </h2>
            <p className="whitetext">Skriv då under och gå med i kampanjen Ja till Nato! Då och då kommer mail med senaste nytt från kampanjen (gratis, och kan enkelt avslutas när man vill).</p>
          </div>

        </div>
        {!isSuccess &&
        <div className="subrow inputs">
          <input type="text"
                 id="name" name="name"
                 className="textinput half"
                 placeholder="Förnamn"
                 onChange={this.handleChange}
                 value={this.state.values.name}/>
          <input type="text"
                 id="surname" name="surname"
                 className="textinput half"
                 placeholder="Efternamn"
                 onChange={this.handleChange}
                 value={this.state.values.surname}/>
          <input type="email"
                 id="email"
                 name="email"
                 className="textinput half"
                 placeholder="E-post"
                 onChange={this.handleChange}
                 value={this.state.values.email}/>
          <input type="text"
                 id="zipcode"
                 name="zipcode"
                 className="textinput half"
                 placeholder="Postnummer"
                 onChange={this.handleChange}
                 value={this.state.values.zipcode}/>
          <div>
            <input type="checkbox"
                   id="consent"
                   name="consent"
                   className="checkbox"
                   onChange={this.handleChange}
                   checked={this.state.values.consent}/>
            <label>Jag godkänner att Moderaterna sparar mina personuppgifter. <a href="https://moderaterna.se/integritetspolicy/hur-vi-hanterar-dina-personuppgifter/" target="_blank" rel="noreferrer" className="wt">Så här hanterar vi dina personuppgifter.</a></label>
          </div>
          <p><small>

          </small></p>
          <button type="submit"
                  className="submitbtn"
                  disabled={!isValid || isSubmitting}>Skicka
          </button>
        </div>
        }
        <div className="subrow">
          {error}
          {success}
        </div>
      </form>
        <div className="row text">
          <h3>Sverige behöver Nato</h3>
          <p>Ett demokratiskt land i Europa har oprovocerat och kallblodigt invaderats av en aggressiv grannstat. Det är återigen tydligt att Vladimir Putin inte respekterar andra länders gränser. Efterkrigstiden har fått ett abrupt slut.</p>
          <p>Nu rustar land efter land upp sitt försvar och i de länder som står utanför Nato ökar viljan att bli gå med i försvarsalliansen.Sverige räknar med att få hjälp av andra om vi blir utsatta för ett militärt angrepp. Men det är bara om vi är med i Nato som vi faktiskt blir garanterade hjälp, eftersom Natos medlemsländer har åtagit sig att hjälpa till om ett annat medlemsland blir angripet.</p>
          <h3>Ansök om Natomedlemskap med Finland</h3>
          <p>I Finland bubblar det. Mer än halva befolkningen säger nu ja till att bli medlemmar i försvarsalliansen. Sverige och Finland måste nu göra gemensam sak och ta steget in i Nato. Ensam är inte stark.</p>
          <p><strong className="primary">Vill du också att Sverige ska vara garanterade militär hjälp av våra vänner? Skriv på vår lista och gå med i kampanjen för svenskt Natomedlemskap! Tipsa gärna andra som vill samma sak.</strong></p>
        </div>


      </div>
    );
  }
}
